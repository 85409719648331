<template>
  <div class="linked-post">
    <div class="linked-post__title">
      <span class="linked-post__font linked-post__font--title">
        {{ $t("general.linkedPost.title") }}
      </span>
    </div>

    <div class="linked-post__content">
      <div class="linked-post__placeholder" v-if="!posts.length">
        <div class="linked-post__icon-wrapper">
          <CIcon
            name="empty"
            path="post-create"
            class="linked-post__icon linked-post__icon--empty"
          />
        </div>

        <div class="linked-post__text">
          <span class="linked-post__font linked-post__font--placeholder">
            {{ $t("general.linkedPost.placeholderText") }}
          </span>
        </div>
      </div>

      <div>
        <div class="linked-post__list" v-if="posts.length">
          <CRadio
            v-model="selectedPost"
            v-for="(item, index) in posts"
            :key="`${index + '-group'}`"
            :name="item.id"
            :value="item"
            allow-uncheck
            @change="selectPost"
            group-name="linked-post"
            class="linked-post__card-wrapper"
          >
            <template #default="{ isActive, value: post }">
              <PostTile
                is-disabled-link
                :post="post"
                class="linked-post__card"
                :class="{ 'linked-post__card--active': isActive }"
              />
            </template>
          </CRadio>
        </div>

        <button
            v-if="hasMorePosts"
          type="button"
          class="linked-post__button"
          @click="loadMore"
        >
          <span class="linked-post__font linked-post__font--button">
            {{ $t("general.linkedPost.loadMore") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";
import { mapGetters, mapMutations } from "vuex";
import { postType } from "@/config/postsType";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CRadio from "@/features/ui/CRadio.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";

export default {
  name: "LinkedPost",
  components: { PostTile, CRadio, CIcon },
  mixins: [waitRequest, validationError],
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      initialPage: 1,
      nextPage: 1,
      selectedPost: null,
    };
  },

  mounted() {
    this.initialLoadPost();
  },

  computed: {
    ...mapGetters({
      linkedPosts: "linkedPost/linkedPosts",
      linkedPostsMeta: "linkedPost/linkedPostsMeta",
    }),

    hasMorePosts() {
      return Boolean(this.linkedPostsMeta?.next_page_url);
    },

    posts() {
      return this.linkedPosts || [];
    },

    page() {
      return this.linkedPostsMeta?.current_page || 1;
    },
  },

  methods: {
    ...mapMutations({
      setPosts: "linkedPost/setPosts",
      updatePosts: "linkedPost/updatePosts",
      setMeta: "linkedPost/setMeta",
    }),

    selectPost(post) {
      this.$emit("linked-post-selected", post);
    },

    loadPosts(successCallback) {
      if (this.requestInProgress) return;

      const itemsPerPage = 6;

      const url = new URL(
        `/posts/user/${this.userId}`,
        process.env.VUE_APP_API_URL
      );

      url.searchParams.set("page", this.nextPage);
      url.searchParams.set("per_page", itemsPerPage);
      url.searchParams.set("type", postType.posts);

      const requestUrl = url.pathname + url.search;

      this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadPost() {
      this.$showSpinner();

      this.loadPosts(this.setCurrentPosts);
    },

    loadMore() {
      this.nextPage = this.page + 1;
      if (this.hasMorePosts) return this.loadPosts(this.updateCurrentPosts);
    },

    setCurrentPosts(data) {
      const { current_page, data: posts } = data;

      this.setPosts({ posts });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const posts = data.data;

      this.updatePosts({ posts });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.linked-post {
  width: 100%;
  padding: em(22) em(17) em(0) em(17);
  border-radius: em(5);
  border: 1px solid $app-gray-2;
  background: $app-gray;

  &__title {
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: em(19);
    margin-bottom: em(17);
  }

  &__card-wrapper {
    width: width-with-gap(3, 10px);
    &:nth-child(n + 4) {
      margin-top: em(10);
    }
  }

  &__card {
    display: flex;
    width: 100%;
    border-radius: em(5);
    border: em(3) solid $app-gray;
    pointer-events: none;

    &--active {
      border-color: $app-blue;
    }
  }

  &__font {
    font-family: $font-default;
    font-style: normal;

    &--title {
      color: $black;
      font-size: em(16);
      font-weight: 600;
    }

    &--placeholder {
      color: $app-gray-14;
      text-align: center;
      font-size: em(12);
      font-style: normal;
      font-weight: 400;
    }

    &--button {
      color: $white;
      text-align: center;

      font-size: em(12);
      font-style: normal;
      font-weight: 600;
    }
  }

  &__content {
  }

  &__placeholder {
  }

  &__icon-wrapper {
    margin-top: em(22);
    text-align: center;
  }

  &__icon {
    &--empty {
      color: $app-gray-14;
      width: em(38);
      height: em(38);
    }
  }

  &__text {
    margin-top: em(15);
    margin-bottom: em(17);
    text-align: center;
  }

  &__button {
    margin-bottom: em(17);
    padding: em(7);
    border-radius: em(5);
    background: $app-blue;
    color: $white;
  }
}
</style>
