<template>
  <div role="button" @click.stop="handleClick(setting)">
    <div class="settings-text">
      <CIcon
        :name="setting.icon"
        path="post-create"
        class="settings-text__icon"
      />

      <span class="settings-text__font">
        {{ setting.name }}
      </span>

      <span v-if="setting.optional" class="settings-text__optional">
        <span class="settings-text__font settings-text__font--optional">
          {{ setting.optional }}
        </span>
      </span>

      <div v-if="setting.error" class="settings-text__error">
        <span class="settings-text__font settings-text__font--error">
          {{ setting.error }}
        </span>
      </div>
    </div>

    <span class="settings-text__addition">
      <!--              :is-active="isNSFWContent"-->
      <CButtonToggle
        v-if="setting.isToggle"
        class="settings-text__toggle"
        :is-active="setting.isActive"
        @click="setting.action"
      />

      <div v-else class="settings-text__arrow-wrapper">
        <span
          v-if="optionalInfo"
          class="settings-text__font settings-text__font--info"
        >
          {{ optionalInfo }}
        </span>

        <CIcon
          name="arrow-menu-to-right"
          class="settings-text__icon settings-text__icon--arrow"
        />
      </div>
    </span>
  </div>
</template>
<script>
import CButtonToggle from "@/features/ui/CButtonToggle.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "SettingsButton",
  components: {
    CIcon,
    CButtonToggle,
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    optionalInfo() {
      return this.setting.optionalInfo?.length > 20
        ? `${this.setting.optionalInfo.slice(0, 20)}...`
        : this.setting.optionalInfo || "";
    },
  },
  methods: {
    handleClick(settings) {
      if (settings.isToggle) return;

      settings.action();
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-text {
  $parent: &;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &__optional {
    display: inline-flex;
    margin-left: em(8);
    padding: em(4) em(5) em(3) em(5);

    border-radius: em(4);
    background: $app-gray-4;
  }

  &__error {
    position: absolute;
    top: 100%;
    font-size: 0.75em;
  }

  &__arrow-wrapper {
    pointer-events: none;
  }

  &__font {
    color: $black;
    text-align: center;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    &--addition {
      color: $app-blue;
      font-size: em(12);
      font-weight: 600;
    }

    &--optional {
      color: $app-gray-15;
      font-size: em(8);
      line-height: 1;
    }

    &--error {
      white-space: nowrap;
      font-size: em(12);
      color: $red;
    }

    &--info {
      font-weight: 400;
      font-size: em(14);
      line-height: 86%;
      text-align: right;
      color: $app-gray-42;
    }
  }

  &__addition {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  &__toggle {
    font-size: 60%; // change toggle default size
  }

  &__icon {
    display: inline-block;
    width: em(18);
    min-width: em(18);
    height: em(18);
    margin-right: em(13);

    &--arrow {
      pointer-events: none;
      width: em(18);
      height: em(18);
      color: $app-gray-5;
    }
  }
}
</style>
