<template>
  <b-row class="post-create">
    <b-col>
      <PageHeader :title="$t('general.postsCreate.newPost')">
        <button
          type="button"
          @click="submitPost"
          class="base-button post-create__button post-create__button--header"
        >
          <span class="base-button__font">{{ $t("general.post") }}</span>
        </button>
      </PageHeader>

      <div class="post-create__tabs">
        <CRadio
          v-model="selectedTab"
          v-for="(item, index) in tabs"
          :key="`${index + '-tab'}`"
          :name="item.name"
          :value="item"
          group-name="post-create-tabs"
          class="post-create-tabs"
        >
          <template #default="{ isActive, value: tab }">
            <div
              class="post-create-tabs__item"
              :class="{
                'post-create-tabs__item--active': isActive,
                'post-create-tabs__item--disabled': item.disabled,
              }"
            >
              <span class="post-create-tabs__icon-wrapper">
                <CIcon
                  :name="tab.icon"
                  path="post-create"
                  class="post-create-tabs__icon"
                  :class="{ [`post-create-tabs__icon--${tab.icon}`]: tab.icon }"
                />
              </span>

              <span class="post-create-tabs__font"> {{ tab.name }} </span>
            </div>
          </template>
        </CRadio>
      </div>

      <transition name="fade" mode="out-in">
        <AppInfo
          v-if="isFlowSelect"
          :text="$t('general.postsCreate.flowMessage')"
        />

      </transition>


      <div class="mt-3">
        <AppInfo
          v-if="errors.has('schedule')"
          type="error"
          :text="errors.first('schedule')"
        />
        <AppInfo
          v-if="errors.has('is_flow')"
          type="error"
          :text="errors.first('is_flow')"
        />
      </div>


      <b-row class="">
        <div class="w-100" v-if="isPollTabActive">
          <b-form class="p-3 rounded bg-light mx-3 mt-3">
            <div class="d-flex align-items-center mb-2 w-100">
              <div class="flex-grow-1 mr-2">{{ $t("general.poll") }}</div>
              <div class="text-end ml-2 d-flex">
                <button
                  type="button"
                  class="d-inline-flex mr-2"
                  @click.stop="$popup.open('ExpirationPopup')"
                >
                  {{ expire }}
                </button>

                <b-link class="" @click="pollRemove">
                  <i class="bi-x-circle-fill" />
                </b-link>
              </div>
            </div>

            <div
              v-for="(option, index) in poll"
              :key="index"
              class="mb-2 d-flex gap-2 align-items-center"
            >
              <b-form-input
                name="options[]"
                :placeholder="$t('general.enter-option')"
                v-model="poll[index]"
                size="sm"
                class="flex-fill border"
              />

              <b-link class="ml-2" @click="pollRemoveOption(index)">
                <i class="bi-x" />
              </b-link>
            </div>

            <b-link class="small text-decoration-none" @click="pollAddOption">
              {{ $t("general.add-option") }}
            </b-link>

            <div v-if="errors.has('poll')">
              <span class="text-danger">
                {{ errors.first("poll") }}
              </span>
            </div>
          </b-form>
        </div>

        <!--        <div-->
        <!--          class="d-flex flex-wrap mt-3 mx-3"-->
        <!--          v-if="-->
        <!--            expire > 0 ||-->
        <!--            (scheduleDate != null && scheduleTime != null) ||-->
        <!--            price > 0-->
        <!--          "-->
        <!--        >-->
        <ui-post-option-info
          v-if="expire > 0"
          :info="$tc('general.x-days', expire)"
          @close="expireRemove()"
          class="mr-2"
        />

        <!--          <ui-post-option-info-->
        <!--            v-if="scheduleDate && scheduleTime"-->
        <!--            :info="scheduleFormat()"-->
        <!--            @close="scheduleRemove()"-->
        <!--            class="mr-2"-->
        <!--          />-->

        <!--          <ui-post-option-info-->
        <!--            v-if="price > 0"-->
        <!--            :info="priceFormat()"-->
        <!--            @close="priceRemove()"-->
        <!--            class="mr-2"-->
        <!--          />-->
        <!--        </div>-->

        <div class="post-create__textarea">
          <CTextarea
            v-model.trim="message"
            theme="create-post"
            :rules="'required|max:2200'"
            name="message"
            :placeholder="$t('general.postsCreate.messagePlaceholder')"
          />

          <AppCount
            class="post-create__counter"
            :amount="message.length"
            max="2200"
          />
        </div>

        <div class="mt-2 px-3 w-100">
          <ui-media-uploader
            :key="resetUiKey"
            v-model="media"
            :is-flow="isFlowSelect"
            ref="uploader"
            @change="mediaChange"
          />
        </div>

        <div class="post-create__uploads">
          <div class="post-create__file">
            <div
              role="button"
              @click="selectVault"
              class="button post-create__button post-create__button--upload post-create__button--upload--vault"
            />
            <span class="post-create__font post-create__font--upload">
              {{ $t("general.postsCreate.uploadVault") }}
            </span>
          </div>

          <div class="post-create__file">
            <input
              ref="videoInput"
              type="file"
              :accept="mediaTypes"
              @change="uploadFromDevice"
              hidden
            />
            <button
              ref="file"
              type="button"
              @click="mediaClick"
              class="button post-create__button post-create__button--upload"
            />
            <span class="post-create__font post-create__font--upload">
              {{ $t("general.postsCreate.upload") }}
            </span>
          </div>
        </div>

        <div class="post-create__settings">
          <SettingsButton
            type="button"
            class="settings post-create__button"
            v-for="setting in filteredSettings"
            :key="setting.id"
            :setting="setting"
          />
        </div>

        <div class="w-100 p-3" v-if="isShowPreview">
          <LinkedPost
            v-if="isFlowSelect"
            :user-id="userId"
            @linked-post-selected="linkedPostSelected"
          />
        </div>

        <div class="invalid-feedback px-3 py-2" id="errors">
          <div v-for="(value, key) in errors" :key="key">
            <div v-for="(error, k) in value" :key="k">
              {{ error }}
            </div>
          </div>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

import { mapGetters, mapMutations } from "vuex";

import Media from "@/components/models/Media";

import PageHeader from "@/layout/PageHeader.vue";
// import UiPostOptionInfo from "@/components/ui/UiPostOptionInfo";
import UiMediaUploader from "@/features/containers/post-create/components/UiMediaUploader.vue";
import CRadio from "@/features/ui/CRadio.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CTextarea from "@/features/ui/CTextarea.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import LinkedPost from "@/features/containers/post-create/LinkedPost.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import mux from "@/mixins/mux";

import {
  availableVideoTypes,
  availableImagesTypes,
  flowVideoMaxLength,
  postVideoMaxLength,
} from "@/config/video";
import SettingsButton from "@/features/containers/post-create/components/SettingsButton.vue";
import AppCount from "@/features/components/AppCounter.vue";
import User from "@/components/models/User";
import Post from "@/components/models/Post";
import uploadFromDevice from "@/mixins/uploadFromDevice";
import {
  formatSchedulePostDate,
  formatSchedulePostDateNoUTC,
} from "@/tools/helpers";
import lockSettings from "@/mixins/lockSettings";

const tabs = {
  flow: "flow",
  post: "post",
  poll: "poll",
};

export default {
  name: "PageNewPost",
  components: {
    AppCount,
    SettingsButton,
    LinkedPost,
    AppInfo,
    CTextarea,
    CIcon,
    CRadio,
    PageHeader,
    // UiPostOptionInfo,
    UiMediaUploader,
  },

  mixins: [waitRequest, validationError, mux, uploadFromDevice, lockSettings],

  data() {
    return {
      availableMinDate: new Date().toISOString().slice(0, 16),
      ref_post_id: null,
      ownerId: null,
      // errors: {},
      message: "",
      currencySign: process.env.VUE_APP_CURRENCY_SIGN,
      poll: [],

      expireOptions: {
        0: this.$tc("general.x-days", 0),
        1: this.$t("general.popups.expiration.options.24"),
        3: this.$t("general.popups.expiration.options.3"),
        7: this.$t("general.popups.expiration.options.7"),
      },
      scheduleDate: null,
      scheduleTime: null,
      amount: null,

      nsfw: false,
      allow_comments: true,
      allow_tips: true,
      access_type: 0,
      published_at: null,

      mediaTypes:
        availableVideoTypes.toString() + availableImagesTypes.toString(),
      isPostFlow: false,
      isEdit: false,
      isEditFlow: false,
      tab: {},
      isShowPreview: false,

      resetUiKey: Math.random(),
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
      settings: "postCreate/settings",
      selectedCategories: "postCreate/selectedCategories",
      lockOption: "postCreate/lockOption",
      location: "postCreate/location",
      expirations: "postCreate/expirations",
      lockPrice: "postCreate/lockPrice",
      selectedVaultMedia: "media/selectedVaultMedia",
    }),
    userId() {
      return this.ownerId ?? this.user.id;
    },

    expire() {
      return this.expireOptions[this.expirations];
    },

    selectedTab: {
      get() {
        return this.tab;
      },
      set(val) {
        if (val.disabled) return;

        this.tab = val;
      },
    },

    tabs() {
      return [
        {
          id: 2,
          name: "Post",
          icon: "image",
          type: tabs.post,
          disabled: this.isEdit && this.isEditFlow,
        },
        {
          id: 1,
          name: "Flow",
          icon: "flow",
          type: tabs.flow,
          disabled: this.isEdit && !this.isEditFlow,
        },
        {
          id: 3,
          name: "Poll",
          icon: "poll",
          type: tabs.poll,
          disabled: this.isEdit && this.isEditFlow,
        },
      ];
    },

    lockSettingsText() {
      const text =
        this.lockSettings.find((item) => item.id === this.lockOption)?.name ||
        "";
      const addithonText = this.lockOption === 3 ? `${this.lockPrice}$` : "";

      return `${text} ${addithonText}`;
    },

    settingsList() {
      return [
        {
          id: 0,
          optional: this.$t("general.postsCreate.optional"),
          isShow: this.isFlowSelect,
          isToggle: false,
          name: this.$t("general.postsCreate.settings.category"),
          icon: "category",
          action: () => this.$popup.open("FlowPreferencesPopup"),
        },
        {
          id: 1,
          optional: this.$t("general.postsCreate.optional"),
          isShow: true,
          isToggle: false,
          name: this.$t("general.postsCreate.settings.location"),
          icon: "location",
          optionalInfo: this.location?.address ?? "",
          action: () => this.$popup.open("LocationPopup"),
        },
        {
          id: 2,
          isShow: true,
          isToggle: false,
          name: this.$t("general.postsCreate.settings.whoCanSeePost"),
          icon: "lock",
          optionalInfo: this.lockSettingsText,
          error:
            this.errors &&
            this.errors.has("price") &&
            this.errors.first("price"),
          action: () => this.$popup.open("LockPopup"),
        },
        {
          id: 3,
          isShow: true,
          isToggle: true,
          isActive: this.allow_comments,
          name: this.$t("general.postsCreate.settings.allowComments"),
          icon: "comments",
          action: (val) => {
            this.allow_comments = val;
          },
          // action: () => this.$popup.open("ContentPreferencesPopup", {}, 2),
        },
        {
          id: 4,
          isShow: true,
          isToggle: true,
          isActive: this.allow_tips,
          name: this.$t("general.postsCreate.settings.allowTips"),
          icon: "tip",
          action: (val) => {
            this.allow_tips = val;
          },
          // action: () => this.$popup.open("ContentPreferencesPopup", {}, 2),
        },
        {
          id: 5,
          isShow: true,
          isToggle: true,
          isActive: this.nsfw,
          name: this.$t("general.postsCreate.settings.nsfw"),
          icon: "nsfw",
          action: (val) => {
            this.nsfw = val;
          },
        },
        {
          id: 6,
          isShow: this.isFlowSelect,
          isToggle: true,
          isActive: this.isShowPreview,
          name: this.$t("general.postsCreate.settings.preview"),
          icon: "preview",
          action: this.setPreview,
        },
        {
          id: 7,
          isShow: !this.isFlowSelect,
          isToggle: false,
          isActive: this.isShowPreview,
          name: this.$t("general.postsCreate.settings.scheduleTime"),
          icon: "clock",
          optionalInfo: this.time,
          action: this.setSchedule,
        },
      ];
    },

    filteredSettings() {
      return this.settingsList.filter((item) => item.isShow);
    },

    postId() {
      return this.$route.params.id;
    },

    isFlowSelect() {
      return this.selectedTab.type === tabs.flow; // flow tab
    },

    isPollTabActive() {
      return this.selectedTab.type === tabs.poll;
    },

    isCreatorRole() {
      return (
        this.user.role.includes(User.ROLE_CREATOR) ||
        this.user.role.includes(User.ROLE_ADMIN)
      );
    },

    price: {
      get() {
        return this.lockPrice || 0;
      },
      set(val) {
        this.amount = val;
      },
    },

    time() {
      if (!this.published_at) return "";

      const date = new Date(this.published_at);
      const year = date.getFullYear().toString().slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const day = String(date.getDate()).padStart(2, "0");
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const locale = Intl.DateTimeFormat().resolvedOptions().locale;
      let options = {
        hour: "numeric",
        minute: "numeric",
        hour12: locale.includes("en-US"),
      };

      const formattedTime = new Intl.DateTimeFormat(locale, options).format(
        date
      );

      return `${day}/${month}/${year} ${formattedTime} ${timeZone}`;
    },
  },

  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.setSelectedMedia([]);
  },

  methods: {
    formatSchedulePostDate,
    formatSchedulePostDateNoUTC,
    ...mapMutations({
      setSettings: "postCreate/setSettings",
      setCategories: "postCreate/setCategories",
      setLockOption: "postCreate/setLockOption",
      setLocation: "postCreate/setLocation",
      setExpirations: "postCreate/setExpirations",
      setLockPrice: "postCreate/setLockPrice",
      setSelectedMedia: "media/setSelectedMedia",
    }),
    initState() {
      this.reset();
      this.getSettings().then(() => {
        if (this.postId) {
          this.loadPost().then(() => {
            this.isEdit = true;

            if (this.isEditFlow) {
              this.tab = this.tabs[flow];
            } else {
              this.tab = this.tabs[post];
            }
          });
        }
      });
      this.setupMuxInput(this.$refs.videoInput);

      if (!this.isCreatorRole) {
        this.$router.push("/become-creator");
      }

      const post = 0;
      const flow = 1;

      if (this.postId) {
        this.loadPost().then(() => {
          this.isEdit = true;

          if (this.isEditFlow) {
            this.tab = this.tabs[flow];
          } else {
            this.tab = this.tabs[post];
          }
        });
      } else {
        this.tab = this.tabs[flow];
      }

      if (this.selectedVaultMedia.length) {
        this.media = [...this.selectedVaultMedia];
      }
    },

    setSchedule() {
      this.$popup.open("SchedulePopup", {
        setScheduleCallback: this.setScheduleCallback,
        published_at: this.published_at,
      });
    },

    setPreview() {
      if (!this.isShowPreview) {
        this.$popup.open("PreviewPostPopup", {}, 2);
      }

      this.isShowPreview = !this.isShowPreview;
    },

    linkedPostSelected(post) {
      this.ref_post_id = post.id;
    },

    selectVault() {
      this.vaultPopup(this.isFlowSelect ? 1 : null);
    },

    reset() {
      // this.errors = {};
      this.message = "";
      this.media = [];
      this.poll = [];
      // this.expire = 0;
      this.scheduleDate = null;
      this.scheduleTime = null;
      this.nsfw = false;
      this.published_at = null;

      this.setCategories({ categories: [] });
      this.setLockOption({ lockOption: 0 });
      this.setLocation({ location: null });
      this.setExpirations({ expirations: 0 });
      this.setLockPrice({ lockPrice: null });
    },

    setScheduleCallback(date) {
      this.published_at = this.getPublischeDate(date);
      this.$popup.close(1);
    },

    async loadPost() {
      this.$showSpinner();

      return this.waitRequest(() => {
        return this.$get(
          "/posts/" + this.postId,
          (data) => {
            this.message = data.message;
            this.isEditFlow = data.is_flow;
            this.ownerId = data?.user?.id;

            this.published_at = this.getPublischeDate(data.published_at);

            this.setCategories({ categories: data.categories });
            this.setLockOption({ lockOption: data.access_type });
            this.setLocation({ location: data.location });
            this.setExpirations({ expirations: data.expire });
            this.setLockPrice({ lockPrice: data.price });

            for (let mediaObject of data.media) {
              if (mediaObject.type !== Media.TYPE_VIDEO) continue;

              let screenshotThumb = mediaObject.thumbs.find(
                (thumb) => thumb.url === mediaObject.screenshot
              );
              if (screenshotThumb) {
                mediaObject.scr = screenshotThumb;
              }
            }

            this.media = data.media;

            for (let p of data.poll) {
              this.poll.push(p.option);
            }
            this.expire = data.expires;
            if (data.schedule) {
              this.scheduleDate = dayjs(data.schedule).format("YYYY-MM-DD");
              this.scheduleTime = dayjs(data.schedule).format("HH:mm:ss");
            }
            this.price = data.price / 100;
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      });
    },
    pollAdd() {
      if (this.poll.length == 0) {
        this.poll = ["", ""];
      }
    },
    pollRemove() {
      this.poll = [];
    },
    pollAddOption() {
      this.poll.push("");
    },
    pollRemoveOption(index) {
      this.poll.splice(index, 1);
    },

    scheduleFormatIso() {
      return this.formatSchedulePostDate(this.published_at)
        ?.split("T")
        ?.join(" ");
    },
    getPublischeDate(date) {
      return this.formatSchedulePostDateNoUTC(date)?.split("T")?.join(" ");
    },

    expireRemove() {
      this.expire = 0;
    },

    async submitPost() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.requestInProgress) return;

      let fields = {
        message: this.message,
        is_flow: this.isFlowSelect || this.isPostFlow,
        categories: [],
        allow_comments: this.allow_comments,
        allow_tips: this.allow_tips,
        access_type: this.lockOption,
        is_nsfw: this.nsfw,
      };

      // prepare fields to submit
      fields.media = [...this.media];

      const schedule = this.scheduleFormatIso();
      console.log("");
      if (schedule) {
        fields.schedule = schedule;
      }

      if (this.poll && this.poll.length) {
        fields.poll = this.poll;
      }

      if (this.expirations) {
        fields.expires = this.expirations;
      }

      if (this.lockPrice && this.lockOption === Post.ACCESS_TYPE_PAID_POST) {
        fields.price = this.lockPrice;
      }
      if (this.postId) {
        fields._method = "PUT";
      }

      if (this.location) {
        fields.location = this.location;
      }

      if (this.isFlowSelect) {
        if (this.selectedCategories.length) {
          fields.categories = this.selectedCategories;
        }

        if (this.ref_post_id) {
          fields.ref_post_id = this.ref_post_id;
        }

        if (fields.poll) delete fields.poll;

        fields.media = fields.media.filter(
          (item) => item.type !== Media.TYPE_IMAGE
        );

        if (!this.isFlowDataValid(fields)) return;
      }

      if (!this.isPostVideoValid(fields)) return;

      fields.media = fields.media?.map((mediaItem) => ({
        id: mediaItem.id,
        screenshot: 0,
      }));

      return this.waitRequest(() => {
        const isUpdatePost = Boolean(this.postId);
        const updatePost = `/${this.postId}`;
        const createPost = "";
        const urlAfter = isUpdatePost ? updatePost : createPost;

        const url = `/posts${urlAfter}`;

        return this.$post(
          url,
          fields,
          this.successPost,
          this.failurePost,
          this.checkErrors
        );
      });
    },

    isPostVideoValid(fields) {
      const hasMediaVideo = fields.media.some(
        (item) => item.type === Media.TYPE_VIDEO
      );

      if (hasMediaVideo) {
        // media in vault is valid by default
        if (this.isAllVaultMedia(fields.media)) return true;

        const isSomeVideoToLong = fields.media
          .filter((item) => item.type === Media.TYPE_VIDEO)
          .some((video) => video.meta?.length > postVideoMaxLength);

        if (isSomeVideoToLong) {
          const minutes = postVideoMaxLength / 60;

          this.$popup.open(
            "ErrorPopup",
            {
              title: "Post video to long",
              message: `Some videos are over ${minutes} minutes. The maximum allowed duration for a video is ${postVideoMaxLength} minutes`,
            },
            2
          );

          return false;
        }
      }

      return true;
    },

    isAllVaultMedia(media) {
      const videoList = media.filter(
        (item) =>
          item.type === Media.TYPE_VIDEO &&
          !this.selectedVaultMedia.some((media) => media.id === item.id)
      );

      return !videoList.length;
    },

    isFlowDataValid(fields) {
      const video = fields.media?.[0];
      const isVault = Boolean(video.isVault)
      const hasVideo = Boolean(video?.meta?.length);

      if (!hasVideo && !isVault) {
        this.$popup.open(
          "ErrorPopup",
          {
            title: "Video is required",
            message: "Please add video",
          },
          2
        );

        return false;
      }

      const isVideoToLong = video.meta?.length;
      if (isVideoToLong > flowVideoMaxLength && !isVault) {
        this.$popup.open(
          "ErrorPopup",
          {
            title: "Flow video to long",
            message: `Current video length ${video.meta.length}s. Must be shorter then ${flowVideoMaxLength} seconds. Please change video`,
          },
          2
        );

        return false;
      }

      return true;
    },

    failurePost(error) {
      // this.errors = error.errors;
      console.log("failurePost", error);
    },

    successPost() {
      if (this.postId) {
        this.$bvToast.toast(this.$t("general.post-saved"), {
          autoHideDelay: 5000,
          title: this.$t("general.success"),
          solid: true,
          toaster: "b-toaster-bottom-left flow-toast",
        });
      } else {
        this.$router.replace("/");
      }
    },

    async getSettings() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        const url = `/collect/settings`;

        return this.$get(
          url,
          this.successSettingsCallback,
          this.failureSettingsCallback,
          this.checkErrors
        );
      });
    },

    successSettingsCallback(data) {
      this.setSettings({ settings: data?.settings });
    },

    failureSettingsCallback(value) {
      this.updateContentPreferences(value);
    },
  },
};
</script>

<style scoped lang="scss">
.post-create {
  &__uploads {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1em;
  }

  &__font {
    &--upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: $app-gray-12;
      text-align: center;

      font-size: em(12);
      font-weight: 400;
      pointer-events: none;
      white-space: nowrap;
    }
  }

  &__file {
    position: relative;
    width: width-with-gap();
  }

  &__button {
    &--header {
      height: em(28);
      text-transform: uppercase;
    }

    &--upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: em(53);
      border-radius: em(5);
      border: 1px dashed $app-gray-11;
      overflow: hidden;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: em(54) 0 0 0;
      position: relative;

      &--vault {
        background-color: $app-gray;
      }
    }
  }

  &__tabs {
    margin: em(16) em(0);
    display: flex;
    justify-content: flex-start;
  }

  &__textarea {
    position: relative;
    width: 100%;
    min-height: em(78);
    padding: em(16);
    padding-bottom: em(10);
  }

  &__counter {
    position: absolute;
    bottom: em(24);
    right: em(24);
  }

  &__settings {
    width: 100%;
  }
}

.post-create-tabs {
  &__icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: em(5);
    width: em(16);
    height: em(16);
  }

  &__icon {
  }

  &:not(:first-child) {
    margin-left: em(16);
  }

  &__item {
    display: inline-flex;
    align-items: center;
    padding: em(7) em(8);
    background-color: $app-gray-4;
    border-radius: em(5);
    color: $black;
    transition: color $time-normal $ease, background-color $time-normal $ease;

    &--active {
      background-color: $app-blue;
      color: $white;
    }

    &--disabled {
      pointer-events: none;
      background-color: $app-gray-5;
    }
  }

  &__font {
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__icon {
    &--image {
      width: em(14);
      height: em(14);
    }

    &--poll {
      width: em(14);
      height: em(14);
    }

    &--flow {
      width: em(14);
      height: em(14);
    }
  }
}

.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.settings {
  display: flex;
  justify-content: space-between;
  padding: em(16) em(16);

  &__font {
    &--main {
      color: $black;
      font-family: $font-default;
      font-size: em(14);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__icon {
    width: em(18);
    height: em(18);
    color: $app-gray-5;

    &--addition {
      width: em(14);
      min-width: em(14);
      height: em(14);
      margin-right: em(5);
    }
  }
}
</style>
