<template>
  <div class="app-counter" v-if="max">
    <div
      class="app-counter__font"
      :class="{ [`app-counter__font--${theme}`]: theme }"
    >
      {{ count }} / {{ max }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCount",
  props: {
    amount: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 0,
    },

    theme: {
      type: String,
      default: "",
    },
  },

  computed: {
    count() {
      return this.amount || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-counter {
  &__font {
    color: $app-gray-15;
    font-family: $font-default;
    font-size: em(12);

    &--light {
      color: $app-font-color-placeholder;
    }
  }
}
</style>
